<script setup>
import { ref } from "@vue/reactivity";

const show = ref(false);
const toggleShow = () => {
  show.value = !show.value;
};

const props = defineProps({
  Name: String,
  Desc: String,
});
</script>
<template>
  <section class="mt-5">
    <div
      @click="toggleShow"
      class="md:mx-20 mx-4 p-4 aurora-inner rounded-t-lg flex justify-between cursor-pointer hover:shadow-sm z-50"
    >
      <div>{{ Name }}</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        :class="{ svg: show }"
      >
        <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path
          d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
        />
      </svg>
    </div>
    <transition name="acc">
      <div
        v-show="show"
        class="aurora-inner mx-4 md:mx-20 p-4 pt-2 border-t border-blue-400 z-0"
      >
        {{ Desc }}
      </div>
    </transition>
  </section>
</template>

<style scoped>
svg {
  filter: invert(47%) sepia(9%) saturate(564%) hue-rotate(182deg)
    brightness(90%) contrast(88%);
  transition: all 0.4s ease;
}
.svg {
  transform: rotate(180deg);
}

.acc-enter-active {
  animation: accEnter 0.15s ease-out;
}
.acc-leave-active {
  animation: accLeave 0.15s ease-out;
}
@keyframes accEnter {
  from {
    max-height: 0;
  }
  to {
    max-height: auto;
  }
}
@keyframes accLeave {
  from {
    max-height: auto;
  }
  to {
    max-height: 0;
  }
}
</style>
