<template>
  <hr />
  <section
    class="flex justify-center align-middle aurora-outer"
    style="height: 90vh; padding-top: 8%"
  >
    <div class="text-center">
      <img
        src="https://goldbot.pl/logo1.png"
        alt="goldbot-logo"
        class="w-2/4 mx-auto"
      />
      <div class="text-5xl md:text-6xl">GoldBot</div>
      <!-- <p class="m-2 text-lg">
        GOLD BOT is a multi-purpose bot ready to skill up and boost up your
        Discord server <br />
        Also features auto-moderation, administration, music and much more!
      </p> -->
      <a
        href="https://discord.gg/VUAhVtZvXQ"
        class="p-2 rounded-xl inline-flex my-4 mx-2 aurora-inner text-slate-100 shadow-xl hover:shadow-2xl transition-all"
        target="_blank"
      >
        <svg
          style="max-width: 20px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path
            d="M191.1 224c0-17.72-14.34-32.04-32-32.04L144 192c-35.34 0-64 28.66-64 64.08v47.79C80 339.3 108.7 368 144 368H160c17.66 0 32-14.36 32-32.06L191.1 224zM256 0C112.9 0 4.583 119.1 .0208 256L0 296C0 309.3 10.75 320 23.1 320S48 309.3 48 296V256c0-114.7 93.34-207.8 208-207.8C370.7 48.2 464 141.3 464 256v144c0 22.09-17.91 40-40 40h-110.7C305 425.7 289.7 416 272 416H241.8c-23.21 0-44.5 15.69-48.87 38.49C187 485.2 210.4 512 239.1 512H272c17.72 0 33.03-9.711 41.34-24H424c48.6 0 88-39.4 88-88V256C507.4 119.1 399.1 0 256 0zM368 368c35.34 0 64-28.7 64-64.13V256.1C432 220.7 403.3 192 368 192l-16 0c-17.66 0-32 14.34-32 32.04L320 335.9C320 353.7 334.3 368 352 368H368z"
          />
        </svg>
        Support Server
      </a>
      <a
        href="https://invite.goldbot.pl"
        class="p-2 rounded-xl inline-flex md:my-4 mx-2 aurora-inner text-slate-100 shadow-xl hover:shadow-2xl transition-all"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          class="mb-1"
          style="max-width: 20px"
        >
          <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path
            d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
          />
        </svg>
        Add Bot
      </a>
    </div>
  </section>
</template>
