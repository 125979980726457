<template>
  <div>
    <hr />
    <section
      class="aurora-outer p-4 grid grid-cols-1 md:grid-cols-2 place-items-center"
    >
      <div>Made & Maintained by Termin</div>
      <div>2022 All rights reserved</div>
    </section>
  </div>
</template>
